// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_ICSS_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/typography.css";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[2].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[2].use[2]!../../../styles/colors.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_ICSS_IMPORT_0___, "", true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PumpSettingsOverrideTooltip--container--EgGUi {
   opacity: 1;
   display: flex;
   flex-direction: column;
   min-width: 80px;
   margin: 2px 0;
   max-width: 180px;
 }

 .PumpSettingsOverrideTooltip--row--Mh8Fk {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   line-height: 20px;
 }

 .PumpSettingsOverrideTooltip--label--Yjy9X {
   flex-grow: 1;
 }

 .PumpSettingsOverrideTooltip--value--_s7MU {
   margin-left: 20px;
 }

 .PumpSettingsOverrideTooltip--title--jE99D {
   text-align: center;
 }

 .PumpSettingsOverrideTooltip--overrideType--NdbGs {
   font-weight: normal;
 }

 .PumpSettingsOverrideTooltip--overrideType--NdbGs div:last-child {
   font-weight: bold;
 }
`, "",{"version":3,"sources":["webpack://./src/components/daily/pumpsettingsoverridetooltip/PumpSettingsOverrideTooltip.css"],"names":[],"mappings":"AAEC;GACE,UAAU;GACV,aAAa;GACb,sBAAsB;GACtB,eAAe;GACf,aAAa;GACb,gBAAgB;CAClB;;CAEA;GAEE,aAAa;GACb,mBAAmB;GACnB,8BAA8B;GAC9B,iBAAiB;CACnB;;CAEA;GACE,YAAY;CACd;;CAEA;GACE,iBAAiB;CACnB;;CAEA;GAEE,kBAAkB;CACpB;;CAEA;GAEE,mBAAmB;CACrB;;CAEA;GACE,iBAAiB;CACnB","sourcesContent":[" @import '../../../styles/colors.css';\n\n .container {\n   opacity: 1;\n   display: flex;\n   flex-direction: column;\n   min-width: 80px;\n   margin: 2px 0;\n   max-width: 180px;\n }\n\n .row {\n   composes: smallSize from '../../../styles/typography.css';\n   display: flex;\n   flex-direction: row;\n   justify-content: space-between;\n   line-height: 20px;\n }\n\n .label {\n   flex-grow: 1;\n }\n\n .value {\n   margin-left: 20px;\n }\n\n .title {\n   composes: smallSize from '../../../styles/typography.css';\n   text-align: center;\n }\n\n .overrideType {\n   composes: row;\n   font-weight: normal;\n }\n\n .overrideType div:last-child {\n   font-weight: bold;\n }\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PumpSettingsOverrideTooltip--container--EgGUi`,
	"row": `PumpSettingsOverrideTooltip--row--Mh8Fk ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"label": `PumpSettingsOverrideTooltip--label--Yjy9X`,
	"value": `PumpSettingsOverrideTooltip--value--_s7MU`,
	"title": `PumpSettingsOverrideTooltip--title--jE99D ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`,
	"overrideType": `PumpSettingsOverrideTooltip--overrideType--NdbGs PumpSettingsOverrideTooltip--row--Mh8Fk ${___CSS_LOADER_ICSS_IMPORT_0___.locals["smallSize"]}`
};
export default ___CSS_LOADER_EXPORT___;
